import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	BACK_TO_LOGIN,
	DASHBOARD_MEMBERS_SUMMARY,
	DASHBOARD_MEMBERS_SUMMARY_SUCCESS,
	DASHBOARD_MEMBERS_SUMMARY_FAILED,
	DASHBOARD_MEMBERS_SUMMARY_DAILY,
	DASHBOARD_MEMBERS_SUMMARY_DAILY_SUCCESS,
	DASHBOARD_MEMBERS_SUMMARY_DAILY_FAILED,
	TOTAL_MEMBER_PER_TIER,
	TOTAL_MEMBER_PER_TIER_SUCCESS,
	TOTAL_MEMBER_PER_TIER_FAILED,
	TOTAL_CC_CUSTOMERS,
	TOTAL_CC_CUSTOMERS_SUCCESS,
	TOTAL_CC_CUSTOMERS_FAILED,
	DOWNLOAD_SUCCESS,
} from "../../../constants/ActionTypes";
import {
	MembersSumaryApi,
	MembersSumaryDailyApi,
	TotalMemberPerTierApi,
	TotalCcCustomerApi,
} from "../../api/NewDashboard";

function* fetchMembersSummary({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(MembersSumaryApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: DASHBOARD_MEMBERS_SUMMARY_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: DASHBOARD_MEMBERS_SUMMARY_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: DASHBOARD_MEMBERS_SUMMARY_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchMembersSummaryDaily({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(MembersSumaryDailyApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: DASHBOARD_MEMBERS_SUMMARY_DAILY_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: DASHBOARD_MEMBERS_SUMMARY_DAILY_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: DASHBOARD_MEMBERS_SUMMARY_DAILY_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchTotalMemberPerPoint({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(TotalMemberPerTierApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: TOTAL_MEMBER_PER_TIER_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: TOTAL_MEMBER_PER_TIER_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: TOTAL_MEMBER_PER_TIER_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchTotalCcCustomer({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(TotalCcCustomerApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: TOTAL_CC_CUSTOMERS_SUCCESS, payload: response.data });
			} else {
				console.log(response.data)
				yield put({
					type: TOTAL_CC_CUSTOMERS_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: TOTAL_CC_CUSTOMERS_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

export function* getMembersSummary() {
	yield takeEvery(DASHBOARD_MEMBERS_SUMMARY, fetchMembersSummary);
}

export function* getMembersSummaryDaily() {
	yield takeEvery(DASHBOARD_MEMBERS_SUMMARY_DAILY, fetchMembersSummaryDaily);
}

export function* getTotalMemberPerPoint() {
	yield takeEvery(TOTAL_MEMBER_PER_TIER, fetchTotalMemberPerPoint);
}

export function* getTotalCcCustomer() {
	yield takeEvery(TOTAL_CC_CUSTOMERS, fetchTotalCcCustomer);
}

// yield all
export default function* depositSaga() {
	yield all([
		fork(getMembersSummary),
		fork(getMembersSummaryDaily),
		fork(getTotalMemberPerPoint),
		fork(getTotalCcCustomer),
	]);
}
