import Axios from 'axios';

export const searchMembersApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	page,
	sortBy,
	sort,
	search,
	searchUsername,
	searchMobileNumber,
	searchAccountNumber,
	statusList,
}) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "member/search",
			params: {
				userId: userId,
				merchantId: merchantId,
				memberName: search,
				memberUsername: searchUsername,
				mobileNumber: searchMobileNumber,
				accountNumber: searchAccountNumber,
				statusList: statusList,
				sortBy: sortBy,
				sort: sort,
				page: page,
				nRecords: 10,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const searchNonMembersApi = async ({authToken, deviceId, userId, merchantId, page, size, searchName, searchAccountNumber }) => {
    if(authToken != null){
        return Axios({
            method: "GET",
            url: window.ApiURL + 'nonmember/search/non-member-cc',
            params: {
                userId: userId,
                merchantId: merchantId,
                name: searchName,
                rmNumber: searchAccountNumber,
                page: page,
                size: size && size > 0 ? size : 10
            },
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const getNonMemberPointTransactionApi = async ({authToken, deviceId, userId, merchantId, page, size, searchName, searchAccountNumber, searchDirection, dateFrom, dateTo }) => {
    if(authToken != null){
        return Axios({
            method: "GET",
            url: window.ApiURL + 'report/non-member-point-transaction',
            params: {
                memberId: userId,
                merchantId: merchantId,
                memberName: searchName,
                rmNumber: searchAccountNumber,
                direction: searchDirection,
                page: page,
                pageSize: size && size > 0 ? size : 10,
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const viewMemberApi = ({authToken, deviceId, userId, merchantId, id}) => {
    if(authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'member/view',
            params: {
                userId : userId,
                merchantId : merchantId,
                memberId : id
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const changeStatusApi = ({authToken, deviceId, userId, merchantId, id, status}) => {
    if(authToken != null){
        return Axios({
            method: 'post',
            url: window.ApiURL + 'member/changeStatus',
            data: {
                userId : userId,
                merchantId : merchantId,
                memberId : id,
                status : status
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    //    saya programmer ter pro se jakarta pusat. ga ada yang lebih pro dari saya
    };
};

export const approvalMemberApi = (data) => {
	if (data.authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + data.api,
			data: {
				userId: data.userId,
				merchantId: data.merchantId,
				memberIdList: data.memberIdList,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const uploadMemberApi = ({ authToken, deviceId, userId, merchantId, members }) => {
	if (authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "member/upload",
			data: {
				userId: userId,
				merchantId: merchantId,
				members: members,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const uploadTransactionApi = ({ authToken, deviceId, userId, merchantId, requestList }) => {
	if (authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "promotion/posting/multiple",
			data: {
				userId: userId,
				merchantId: merchantId,
				requestList: requestList,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const memberDelinquencyApi = (data) => {
	if (data.authToken != null) {
		let page = data.page < 0 ? 0 : data.page;
		return Axios({
			method: "GET",
			url: window.ApiURL + "501/deliquency/getMemberListDelinquency",
			params: {
				userId: data.userId,
				merchantId: data.merchantId,
				memberUsername: data.memberDelinquency,
				rmNumber: data.rmNumber,
				isDelinquentStatus: data.isDelinquentStatus,
				isNonDelinquentStatus: data.isNonDelinquentStatus,
				page: page,
				nRecords: 10,
				pageSize: 10,
			},
			data: {},
			headers: {
				"Content-Type": "application/json",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const viewMemberDelinquencyApi = (data) => {
	if (data.authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "501/deliquency/getMemberListDetail",
			data: {
				userId: data.userId,
				merchantId: data.merchantId,
				memberUsername: data.memberUsername,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};
