import Axios from 'axios';

export const updateValidationVoucherApi = ({authToken, deviceId, userId, merchantId, data}) => {
    if(authToken != null){

        return Axios({
            method: 'post',
            url: window.ApiURL + 'point-transaction/redemption/voucher/redeem',
            data: {
                userId: userId,
                merchantId: merchantId,
                memberId : data.memberId,
                voucherBalanceId : data.voucherBalanceId,
                storeId: "",

            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};