import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	BACK_TO_LOGIN,
	POINT_ACCURAL,
	POINT_ACCURAL_SUCCESS,
	POINT_ACCURAL_FAILED,
	POINT_ISSUING,
	POINT_ISSUING_SUCCESS,
	POINT_ISSUING_FAILED,
	POINT_REDEEMED,
	POINT_REDEEMED_SUCCESS,
	POINT_REDEEMED_FAILED,
	TOTAL_CC_POINTS,
	TOTAL_CC_POINTS_SUCCESS,
	TOTAL_CC_POINTS_FAILED,
	DOWNLOAD_SUCCESS,
} from "../../../constants/ActionTypes";
import {
	PointAccuralApi,
	PointIssuingApi,
	PointRedeemedApi,
	TotalCcPointsApi,
} from "../../api/NewDashboard";

function* fetchPointAccural({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(PointAccuralApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: POINT_ACCURAL_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: POINT_ACCURAL_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: POINT_ACCURAL_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchPointIssuing({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(PointIssuingApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: POINT_ISSUING_SUCCESS, payload: response.data });
			} else if (response.data.abstractResponse.responseStatus === "INQ902") {
				yield put({ type: POINT_ISSUING_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: POINT_ISSUING_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			console.error("error :", error);
			yield put({
				type: POINT_ISSUING_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchPointRedeemed({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(PointRedeemedApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: POINT_REDEEMED_SUCCESS, payload: response.data });
			} else if (response.data.abstractResponse.responseStatus === "INQ902") {
				yield put({ type: POINT_REDEEMED_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: POINT_REDEEMED_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: POINT_REDEEMED_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

function* fetchTotalCcPoints({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(TotalCcPointsApi, payload);
			if (response.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: TOTAL_CC_POINTS_SUCCESS, payload: response.data });
			} else {
				yield put({
					type: TOTAL_CC_POINTS_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			yield put({
				type: TOTAL_CC_POINTS_FAILED,
				payload: error.response.data.abstractResponse.responseMessage,
			});
		}
	}
}

export function* getPointAccural() {
	yield takeEvery(POINT_ACCURAL, fetchPointAccural);
}

export function* getPointIssuing() {
	yield takeEvery(POINT_ISSUING, fetchPointIssuing);
}

export function* getPointRedeemed() {
	yield takeEvery(POINT_REDEEMED, fetchPointRedeemed);
}

export function* getTotalCcPoints() {
	yield takeEvery(TOTAL_CC_POINTS, fetchTotalCcPoints);
}

// yield all
export default function* depositSaga() {
	yield all([
		fork(getPointAccural),
		fork(getPointIssuing),
		fork(getPointRedeemed),
		fork(getTotalCcPoints),
	]);
}
