import {
	REDEEMED_POINTS,
	REDEEMED_POINTS_SUCCESS,
	REDEEMED_POINTS_FAILED,
	REDEEMED_REWARD,
	REDEEMED_REWARD_SUCCESS,
	REDEEMED_REWARD_FAILED,
	POINTS_REDEMPTION,
	POINTS_REDEMPTION_SUCCESS,
	POINTS_REDEMPTION_FAILED,
	CUSTOMERS_REDEEMED,
	CUSTOMERS_REDEEMED_SUCCESS,
	CUSTOMERS_REDEEMED_FAILED,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
	loader: false,
	alertMessage: "",
	showMessage: false,
	listDataRedeemedPoints: null,
	listDataRedeemedReward: null,
	listDataCustomerRedeemed: null,
	listDataPointsRedemtion: null,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REDEEMED_POINTS: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				listDataRedeemedPoints: null,
			};
		}

		case REDEEMED_REWARD: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				listDataRedeemedReward: null,
			};
		}

		case CUSTOMERS_REDEEMED: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				listDataCustomerRedeemed: null,
			};
		}

		case POINTS_REDEMPTION: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				listDataPointsRedemtion: null,
			};
		}

		// Response
		case REDEEMED_POINTS_SUCCESS: {
			return {
				...state,
				loader: false,
				listDataRedeemedPoints: action.payload.result.rewardList,
			};
		}

		case REDEEMED_POINTS_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case REDEEMED_REWARD_SUCCESS: {
			return {
				...state,
				loader: false,
				listDataRedeemedReward: action.payload.result.rewardList,
			};
		}

		case REDEEMED_REWARD_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case CUSTOMERS_REDEEMED_SUCCESS: {
			return {
				...state,
				loader: false,
				listDataCustomerRedeemed: action.payload.result,
			};
		}

		case CUSTOMERS_REDEEMED_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case POINTS_REDEMPTION_SUCCESS: {
			return {
				...state,
				loader: false,
				listDataPointsRedemtion: action.payload.result,
			};
		}

		case POINTS_REDEMPTION_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		default:
			return state;
	}
};
