import Axios from "axios";

export const MembersSumaryApi = ({ authToken, deviceId, userId, merchantId, dataSummary }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/member/summary",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...dataSummary,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const MembersSumaryDailyApi = ({ authToken, deviceId, userId, merchantId, dataDaily }) => {
	console.log(dataDaily, "datadaily");
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/member/summary/daily",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...dataDaily,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const TotalMemberPerTierApi = ({ authToken, deviceId, userId, merchantId, dataTotalMemberPerTier }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/loyalty/tier",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...dataTotalMemberPerTier,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const TotalCcCustomerApi = ({ authToken, deviceId, userId, merchantId, totalCcCustomer }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/member-cc/total",
			params: {
				memberId: userId,
				merchantId: merchantId,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};
