import {
    UPDATE_VALIDATION_VOUCHER,
    UPDATE_VALIDATION_VOUCHER_SUCCESS,
    UPDATE_VALIDATION_VOUCHER_FAILED,
    RESET_STATUS,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    validationVoucherData: [],
    recordInfo: {
        totalRecords: 0,
        page : 0,
        nrecords : 0
    },
    updateSuccess: false,
    updateFailed: false,
    createSuccess: false,
    createFailed: false,

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_VALIDATION_VOUCHER: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: ''
            }
        }

        case RESET_STATUS : {
            return {
                ...state,
                alertMessage: '',
                updateSuccess: false,
                updateFailed : false
            }
        }

        // Response
        case UPDATE_VALIDATION_VOUCHER_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                validationVoucherData: action.payload,
                updateSuccess : true,
                updateFailed: false,
            }
        }

        case UPDATE_VALIDATION_VOUCHER_FAILED: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                updateSuccess : false,
                updateFailed: true,
            }
        }

        default:
            return state;
    }
}