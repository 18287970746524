import Axios from "axios";

export const PointAccuralApi = ({ authToken, deviceId, userId, merchantId, pointAccural }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/loyalty/promotion",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...pointAccural
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const PointIssuingApi = ({ authToken, deviceId, userId, merchantId, dataPoints }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/merchant/availablepoint",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...dataPoints
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const PointRedeemedApi = ({ authToken, deviceId, userId, merchantId, dataPoints }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/merchant/deductpoint",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...dataPoints
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const TotalCcPointsApi = ({ authToken, deviceId, userId, merchantId, totalCcPoints }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "dashboard/member-cc/totalPoint",
			params: {
				memberId: userId,
				merchantId: merchantId,
				...totalCcPoints,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};
